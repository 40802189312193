<template>
  <div class="container" ref="wrapper">
    <van-nav-bar :title="title" left-arrow @click-left="$router.back()">
    </van-nav-bar>

    <van-search
      v-model="page.filterData.name"
      @search="onGoSearch"
      placeholder="请输入搜索关键词"
    />
   

    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="upLoading"
        :finished="finished"
        @load="onLoad"
        finished-text="没有更多了"
      >
        <van-collapse v-model="activeNames" accordion>
          <van-collapse-item
            v-for="item in articles"
            :key="item.id.toString()"
            :name="item.id"
          >
            <template #title>
              <div>
                <div>
                  <h3>{{ item.name }}</h3>
                </div>

                <!-- <div style="float: left">
                    <h4>采购时间：{{ DateFromat(item.orderTime) }}</h4>
                  
                    
                  </div> -->

                <div>
                  <h4>主销部门：{{ item.mainSalesDepartment }}</h4>
                  <h4>
                    <div>零售价格(箱)：{{ item.price1 }}</div>
                  </h4>
                  <!-- <h4>
                    <div>销售价格(瓶)：{{ item.price2 }}</div>
                  </h4> -->
                </div>
              </div>
            </template>
            <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->
            <van-cell>
              <van-col span="24"> 产品编号 ： {{ item.code }} </van-col>
              <van-col span="24"> 品牌 ： {{ item.brand }} </van-col>
              <van-col span="24"> 子品牌 ： {{ item.subBrand }} </van-col>
               <van-col span="24"> 香型： {{ item.flavor }} </van-col>
               <!-- <h4>项目阶段： {{ item.stages }} </h4> -->
              <van-col span="24">规格：{{ item.packSize }}</van-col>
              <van-col span="24" style=""> 产品出厂追溯类别：{{ item.traceType }}</van-col>
              <van-col span="24">产品价格管控类别：{{ item.priceControlType }}</van-col>

              <van-col span="24">产品采购类别：{{ item.sourceType }}</van-col>
              <!-- <van-col span="24">商务负责人：{{ item.personInCharge }}</van-col>
              <van-col span="24">品控：{{ item.qualityControl }}</van-col> -->
                          </van-cell>
          </van-collapse-item>
        </van-collapse>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import { getProductPriceList } from "@/api/productPrice";

//const key = "zn-history";
export default {
  name: "productPrice",
  data() {
    return {
      currentDate: new Date(),
      currentDatestart: new Date(),
      showend: false,
      showstart: false,
      date: "",
      datestart: "",
      scroll: 0,
      title: "产品价格",
      activeNames: "",
      refreshing: false,
      upLoading: false,
      finished: false,
      articles: [],

      page: {
        current: 1,
        fromType: 1,
        pageSize: 10,
        sorterType: 0,
        filterData: {
          name: "",
        },
      },
    };
  },

  activated() {
    //  window.scrollTo(0, this.scroll);
    //  alert( this.$refs.wrapper.scrollTop);
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  created() {
    this.initData();
  },
  updated() {
    this.addpreview();
  },

  watch: {
    // $route(to, from) {
    // },
  },

  methods: {
    DateFromat(data) {
      if (!data) {
        return "";
      }

      return data.replace(" 00:00:00", "").replace("T00:00:00","");
    },
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
    async initData() {
      this.page.sorterType = 0;
    },
    onGoSearch() {
      (this.date = ""),
        (this.datestart = ""),
        (this.page.filterData.startDate = null);
      this.page.filterData.endDate = null;
      this.onSearch();
    },

    onSearch() {
      this.finished = false;
      this.articles = [];
      this.page.current = 1;
      this.page.pageSize = 10;
      this.onLoad();
    },

    async onLoad() {
      this.upLoading = true;
      let aresult = await getProductPriceList({ ...this.page }); //({ ...this.page })
      console.log(aresult);
      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.page.current * this.page.pageSize < aresult.data.total) {
        this.page.current++;
      } else {
        this.finished = true;
      }
    },
    changeSorterType(typeid) {
      if (this.page.filterData.stages != typeid) {
        this.date = "";
        this.datestart = "";
        this.page.filterData.startDate = null;
        this.page.filterData.endDate = null;
        this.page.filterData.stages = typeid;
        this.onSearch();
      }
    },

    changetaskStatus(typeid) {
      if (this.page.filterData.taskStatus != typeid) {
        this.date = "";
        this.datestart = "";
        this.page.filterData.startDate = null;
        this.page.filterData.endDate = null;
        this.page.filterData.taskStatus = typeid;
        this.onSearch();
      }
    },

    GetTaskStatusColor(taskStatus) {
      if (taskStatus.indexOf("提前") >= 0) {
        return { background: "#564156",
            color: "#fff",
         };
      }
      if (taskStatus.indexOf("如期") >= 0) {
        return { background: "rgb(0 176 80)" };
      }
      if (taskStatus.indexOf("延期") >= 0) {
        return { background: "#ffc000",
     
         };
      }
      if (taskStatus.indexOf("停") >= 0) {
        return { background: "#ff0000" };
      }
      return {};
    },

    //加图片预览
    addpreview() {
      var listdiv = document.getElementsByClassName("acontent");

      listdiv.forEach((acontentdiv, index) => {
        var imgs = acontentdiv.getElementsByTagName("img");
        var itemid = listdiv[index].dataset.itemid;
        imgs.forEach((element) => {
          element.setAttribute("preview", itemid);
        });
      });
      this.$previewRefresh();
    },

    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`;
    },
    onConfirm() {
      Date.prototype.Format = function (fmt) {
        //author: meizz
        var o = {
          "M+": this.getMonth() + 1, //月份
          "d+": this.getDate(), //日
          "h+": this.getHours(), //小时
          "m+": this.getMinutes(), //分
          "s+": this.getSeconds(), //秒
          "q+": Math.floor((this.getMonth() + 3) / 3), //季度
          S: this.getMilliseconds(), //毫秒
        };
        if (/(y+)/.test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            (this.getFullYear() + "").substr(4 - RegExp.$1.length)
          );
        for (var k in o)
          if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(
              RegExp.$1,
              RegExp.$1.length == 1
                ? o[k]
                : ("00" + o[k]).substr(("" + o[k]).length)
            );
        return fmt;
      };

      this.showend = false;
      (this.date = this.currentDate.Format("yyyy-MM-dd")),
        (this.page.filterData.endDate = this.currentDate);
      this.onSearch();
    },

    onConfirmstart() {
      Date.prototype.Format = function (fmt) {
        //author: meizz
        var o = {
          "M+": this.getMonth() + 1, //月份
          "d+": this.getDate(), //日
          "h+": this.getHours(), //小时
          "m+": this.getMinutes(), //分
          "s+": this.getSeconds(), //秒
          "q+": Math.floor((this.getMonth() + 3) / 3), //季度
          S: this.getMilliseconds(), //毫秒
        };
        if (/(y+)/.test(fmt))
          fmt = fmt.replace(
            RegExp.$1,
            (this.getFullYear() + "").substr(4 - RegExp.$1.length)
          );
        for (var k in o)
          if (new RegExp("(" + k + ")").test(fmt))
            fmt = fmt.replace(
              RegExp.$1,
              RegExp.$1.length == 1
                ? o[k]
                : ("00" + o[k]).substr(("" + o[k]).length)
            );
        return fmt;
      };

      this.showstart = false;
      (this.datestart = this.currentDatestart.Format("yyyy-MM-dd")),
        (this.page.filterData.startDate = this.currentDatestart);
      this.onSearch();
    },
    getpercentage(item){

      var surplusDuration=item.surplusDuration.replace("天","").replace(" ","");
      var plannedDuration=item.plannedDuration.replace("天","").replace(" ","");

      if(parseInt(surplusDuration)>0){
        return 100-( parseInt(surplusDuration)/parseInt(plannedDuration)*100)
      }
      else{
        return 100;
      }
    }
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}
.acontent {
  /deep/ img {
    max-width: 100% !important;
    display: block;
    border: 0;
  }
  /deep/ video {
    max-width: 100% !important;
  }
}
</style>
