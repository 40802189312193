import request from '@/utils/request'

export function getProductPriceList(params) {
    return request({
        url: '/api/ProductPrice/ListByUser',
        data: { ...params },
        method: 'post',
        header: {
            'Content-Type': 'application/json;charset=utf-8'  //如果写成contentType会报错
        },

    })
}